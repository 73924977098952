<template>
  <header :style="header_gradient" class="report_header">
    <div class="content_container">
      <div class="back-link">
        <a :href="backUrl">
          <BackArrow />
          <span>{{ backText }}</span>
        </a>
      </div>
      <div class="hero_logo" v-html="account.svg_logo_mark"></div>
      <div class="header_contents">
        <div class="spotlight_header">
          <figure v-html="account.svg_logo"></figure>
          <h3>{{ assetType }}</h3>
        </div>
        <div class="title">
          <h1>{{ contentAsset.title }}</h1>
          <h6 v-if="publishedDate">Published: {{ publishedDate }}</h6>
        </div>
      </div>
    </div>
  </header>
</template>
<script lang="ts">
import { gradientColors } from '@lib/colors'
import dayjs from 'dayjs'
import BackArrow from '@components/graphics/BackArrow.vue'

export default {
  components: {
    BackArrow,
  },
  props: {
    account: {
      type: Object,
      required: true,
    },
    contentAsset: {
      type: Object,
      required: true,
    },
    backUrl: {
      type: String,
      required: false,
    },
    backText: {
      type: String,
      required: false,
    },
  },
  computed: {
    publishedDate() {
      const date = this.contentAsset.verified_at || this.contentAsset.created_at

      if (!date) return null

      // If null is passed to dayjs, it shows 'Invalid Date'.
      // If undefined, it shows the current date.
      return dayjs(date).format('MMMM DD, YYYY')
    },
    assetType() {
      switch (this.contentAsset.type) {
        case 'CustomerSpotlight': {
          return 'Customer Spotlight'
        }
        case 'SurveySpotlightAsset': {
          return 'Survey Spotlight'
        }
        case 'SurveySummary': {
          return 'Survey Summary'
        }
        default: {
          return ''
        }
      }
    },
    header_gradient() {
      return `background: radial-gradient(134.88% 877.05% at 15.14% -17.75%, ${this.gradientStartColor} 0%, ${this.gradientEndColor} 100%), no-repeat`
    },
    gradientStartColor() {
      return this.gradientColors[0]
    },
    gradientEndColor() {
      return this.gradientColors[1]
    },
    gradientColors,
  },
}
</script>
<style lang="sass" scoped>
header
  margin-bottom: 3em
</style>

import { Model } from '@vuex-orm/core'
import Question from './Question'
import Recipient from './Recipient'
import SendRequest from './SendRequest'

export default class Survey extends Model {
  static entity = 'surveys'

  static fields() {
    return {
      id: this.attr(null),
      order: this.attr(null), // this is clientside oonly
      state: this.attr('string'),
      locked: this.attr(''),
      type: this.attr(''),
      slug: this.attr(''),
      name: this.attr(''),
      token: this.string(''),
      expires_at: this.string(''),
      expired_text: this.string(''),
      nps_survey: this.boolean(false),
      svg_logo: this.string(null).nullable(),
      description: this.attr(''),
      promotion_text: this.attr(''),
      question_count: this.attr('number'),
      recipients_count: this.attr('number'),
      unrequested_recipient_count: this.attr(null),
      responded_recipients_count: this.attr(null),
      content_asset_count: this.attr('number'),
      custom_named_attribution: this.attr(null),
      custom_survey_landing_page_text: this.attr(null),
      questions: this.hasMany(Question, 'survey_id'),
      recipients: this.hasMany(Recipient, 'survey_id'),
      from_email: this.attr('string'),
      from_first_name: this.attr(''),
      from_last_name: this.attr(''),
      from_title: this.attr(''),
      requested_send_request: this.hasOne(SendRequest, 'survey_id'),
      completed_text: this.attr(''),
      survey_promotions: this.attr(''),
      response_rate: this.attr(null),
      collector_count: this.attr(null),
      last_response_at: this.attr(null),
      recipient_uploads: this.attr(null),
      show_company_name_question: this.boolean(true),
      show_industry_question: this.boolean(true),
      show_company_size_question: this.boolean(true),
      show_role_question: this.boolean(true),
      show_title_question: this.boolean(true),
      show_seniority_question: this.boolean(true),
    }
  }
}
